import React, {ReactElement, useEffect, useState} from 'react'
// @ts-ignore
import HowItWorksAccedi from "@/img/homePage/how-it-works-step-one.svg";
// @ts-ignore
import HowItWorksAcquista from "@/img/homePage/how-it-works-step-two.svg";
// @ts-ignore
import HowItWorksEvento from "@/img/homePage/how-it-works-step-three.svg";
// @ts-ignore
import VendiBigliettoIcon from "@/img/homePage/sell-tickets.svg";
// @ts-ignore
import ticketImage from "@/img/icon/ticket.svg";
// @ts-ignore
import CardAllEvents from "@/img/homePage/card_all_events.svg";
import Layout from '../../components/Layout'
import Hero from '@/components/Hero'
import {useNavigate} from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/it";
import {useApi} from "@/hoc/Api/context";
import Event from "@/models/Event";
import Loader from "@/components/Loader";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

interface HomeCardProps {
    mainIssuer: string;
    secondaryIssuer: string;
    date: string;
    ticketsAvailable: number;
    previewImage: string;
    eventUuid: string;
    className?: string;
}

const Home = ({...props}):ReactElement => {
    const navigate = useNavigate()
    const api = useApi()
    const seo = useSeo()
    const [events, setEvents] = useState<Event[] | undefined>(undefined)
    const [missing, setMissing] = useState<number[]>([])

    useEffect(() => {
        seo.setSeo(SeoPage.homepage);
        api.GetActiveEvents().then((r) => {
            if (r === null) {
                setEvents([])
            } else {
                setEvents(r)
                let missing = []
                for (let i = r.length; i < 3; i++) {missing.push(i)}
                setMissing(missing)
            }
        })
        moment.locale("it")
    }, [])

    const HomeCard = (props: HomeCardProps): ReactElement => {
        return (
            <>
                <div className={`row d-flex col-10 col-md-4 col-lg-4 col-xl-3 border-0 card-group__card ${props.className}`}>
                    <div className="row p-0 justify-content-center align-items-center cursor-pointer"
                         onClick={() => {navigate(`/Partita/${props.eventUuid}`)}}
                    >
                        <div className={"col-12 p-0 m-0 showcase__card-img after"} >
                            <img alt='Partita'
                                 className='card-img-top showcase__card-img'
                                 src={((props.previewImage) ? (props.previewImage) : ("https://via.placeholder.com/1032x600?text=" + props.mainIssuer))}
                            />
                        </div>
                    </div>
                    <p className='showcase__card-img-text cursor-pointer' onClick={() => {navigate(`/Partita/${props.eventUuid}`)}}>
                        <span className='showcase__card-img-text-date'>
                            <Moment locale="it" format="DD MMMM YYYY" style={{letterSpacing: "0.05em"}}>
                                {new Date(props.date)}
                            </Moment>
                        </span>{" - "}
                        <span className='showcase__card-img-text-time'>
                            <Moment format="HH:mm" style={{letterSpacing: "0.05em"}}>
                                {new Date(props.date)}
                            </Moment>
                        </span>
                    </p>
                    <div className='card-body text-center card-group__card-body'>
                        <h4 className='card-title font-weight-normal text--heading-color showcase__match-title'>
                            <span className='font-weight-semibold showcase__home-team'>{props.mainIssuer}</span>
                            vs
                            <span className='font-weight-semibold showcase__guest-team'>{props.secondaryIssuer}</span>
                        </h4>
                        <div className='row d-flex justify-content-center align-items-center'>
                            <div className="col-auto p-0">
                                <span className='showcase__ticket-available'>
                                <p className='card-text showcase__ticket-available-text'>{props.ticketsAvailable} Biglietti disponibili</p>
                            </span>
                            </div>
                            <div className="col-auto">
                                <div className='showcase__icon-container cursor-pointer m-0' onClick={() => {navigate(`/Partita/${props.eventUuid}`)}}>
                                    <i aria-hidden="true" className="icon icon--ticket mx-auto"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (!events) return <Loader />

    return (
            <Layout>
                {/* 1° SECTION */}
                <Hero events={events}/>

                {/* 2° SECTION */}
                <div className='how-it-works container text-center'>
                    <h2 className='how-it-works__header'>Come funziona <span
                        className='font-weight-bold text--primary-color'>Ticketag</span></h2>
                    <div className='row card-deck card-group__feature-deck'>
                        <div
                            className='col-12  col-lg-4 border-0 card-group__card card-group__card-noshadow'
                        >
                            <img alt='Accedi al servizio' src={HowItWorksAccedi}/>
                                <div className='card-body text-center card-group__card-body'>
                                    <h4 className='card-title how-it-works__title'>Accedi al servizio</h4>
                                    <p className='card-text how-it-works__paragraph'>
                                        Iscriviti a Ticketag per acquistare o vendere i biglietti per le partite casalinghe del Pisa.
                                    </p>
                                </div>
                        </div>
                        <div
                            className='col-12 col-lg-4 border-0 card-group__card card-group__card-noshadow'
                        >
                            <img alt='Acquista o vendi un biglietto' src={HowItWorksAcquista}/>
                                <div className='card-body text-center card-group__card-body'>
                                    <h4 className='card-title how-it-works__title'>Acquista o vendi un biglietto</h4>
                                    <p className='card-text how-it-works__paragraph'>
                                        Inserisci i dati della tua Membership Pisa Card per acquistare o vendere un biglietto.
                                    </p>
                                </div>
                        </div>
                        <div
                            className='col-12 col-lg-4 border-0 card-group__card card-group__card-noshadow'
                        >
                            <img alt="Goditi l'evento!" src={HowItWorksEvento}/>
                                <div className='card-body text-center card-group__card-body'>
                                    <h4 className='card-title how-it-works__title'>Goditi l’evento!</h4>
                                    <p className='card-text how-it-works__paragraph'>
                                        Entra allo stadio con la tua Membership Pisa Card e goditi la partita!
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>

                {/* 3° SECTION */}
                {
                    events.length !== 0 ?
                        (
                            <div className="showcase">
                                <div className='row container mx-auto justify-content-center'>
                                    <div className='showcase__title d-flex col-12 justify-content-center justify-content-md-start'>
                                        <h2 className='text-center text-md-left pb-3 pb-md-0'>
                                            {"Accedi alle migliori "}
                                            <span className='font-weight-bold text--primary-color'>
                                                {"Partite"}
                                            </span>
                                        </h2>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"row justify-content-start"}>
                                            <div className={"col-3 d-none col-md-2 d-md-flex"}>
                                                <div className='showcase__header'>
                                                    <div className="line-container"></div>
                                                </div>
                                            </div>
                                            <div className='d-flex col-12 col-md-10 col-lg-7'>
                                                <div className='showcase__header'>
                                                    <p className='text-center text-lg-start showcase__header-par'>
                                                        {"Scegli la partita e acquista un biglietto con la tua "}<span className='font-weight-bold '>{"Membership Pisa Card"}</span>{" direttamente da un abbonato."}
                                                    </p>
                                                    <span className='showcase__header-showmore d-none d-md-block'
                                                          onClick={() => navigate("/Partite")}
                                                    >
                                                        {"SCOPRI DI PIÙ"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"container row mx-auto justify-content-center justify-content-md-between"}>
                                    {
                                        events.slice(0,3).map((event, index) => {
                                            return (
                                                <HomeCard
                                                    key={index}
                                                    mainIssuer={event.mainIssuer}
                                                    secondaryIssuer={event.secondaryIssuer}
                                                    eventUuid={event.uuid}
                                                    date={event.date}
                                                    ticketsAvailable={event.availableTickets}
                                                    previewImage={event.previewImage}
                                                />
                                            )
                                        })
                                    }
                                    <div className="row d-none d-xl-flex col-xl-3 border-0 card-all-events justify-content-center">
                                        <div className="row p-0 justify-content-start align-items-start cursor-pointer" onClick={() => {navigate("/Partite")}}>
                                            <div className={"col-12 p-0 m-0"}>
                                                <img alt='Partita'
                                                     className='card-img-top showcase__card-img'
                                                     src={CardAllEvents}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        missing.map((missed, index) => {
                                            return (<div key={index} className="row d-flex col-10 col-md-4 col-lg-4 col-xl-3 border-0 card-group__card"></div>)
                                        })
                                    }

                                </div>
                            </div>
                        ):
                        null
                }

                {/* 4° SECTION */}
                <div className="ticket-sell row container mx-auto justify-content-center justify-content-lg-between align-items-center">
                    <div className="d-flex col-12 col-md-6 align-content-center row align-items-center justify-content-center">
                        <div className={"row"}>
                            <div className={"col-12 pb-1"}>
                                <h2 className='ticket-sell__explain-header'>
                                    {"Vendi ora il tuo "}
                                    <span className='font-weight-bold text--primary-color'>
                                        Biglietto
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className={"row d-none d-md-flex"}>
                            <div className={"col-12"}>
                                <div className="sell-your-ticket-separator mb-4"></div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 pb-5"}>
                                <p className='ticket-sell__explain-paragraph'>
                                    Se hai un abbonamento puoi rivendere ad altri tifosi del Pisa S.C. le singole partite
                                    alle quali non puoi assistere, e verrai rimborsato del rateo della partita!
                                </p>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 pb-5 pb-md-3"}>
                                <button className={"btn btn--gradient py-1"}
                                        onClick={() => {
                                            navigate("/Profilo/Vendi")
                                        }}
                                >
                                    <div className={"row py-2"}>
                                        <div className={"col-auto d-flex align-items-center justify-content-center p-0 pe-2"}>
                                            <i className='icon icon--ticket icon--white position-relative m-0 p-0'></i>
                                        </div>
                                        <div className={"col-auto d-flex align-items-center justify-content-center p-0"}>
                                            <span className='btn__text-vertical-center'>Scopri come</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"d-flex col-12 col-md-6"}>
                        <img alt='Vendi il tuo biglietto immagine'
                             width={"100%"}
                             src={VendiBigliettoIcon}
                        />
                    </div>
                </div>

            </Layout>
  )
}

export default Home
