import React, {ReactElement, useEffect, useState} from 'react'
import Select, {StylesConfig} from "react-select";
import {useNavigate} from "react-router-dom";
import variables from "@/models/ColorsVariables";
import Event from "@/models/Event";
import * as _ from "lodash";
import Loader from "@/components/Loader";
import RangeSelector from "@/components/RangeSelector";
// @ts-ignore
import ticketImage from "@/img/icon/ticket.svg";
import {useTeam} from "@/hoc/Team/TeamProvider";

interface selectOption {
    value: string;
    label: string;
}

const Hero = (props: {events: Event[]}):ReactElement => {
    const navigate = useNavigate()

    const nTicketsOptions: selectOption[] = [
        {
            value: "1",
            label: "1"
        },
        {
            value: "2",
            label: "2"
        },
        {
            value: "3",
            label: "3"
        }
    ]

    const [events, setEvents] = useState<Event[]>([]);

    const [issuersOptions, setIssuersOptions] = useState<selectOption[]>([]);
    const [eventsOptions, setEventsOptions] = useState<selectOption[]>([]);

    const [issuer, setIssuer] = useState<selectOption | undefined>(undefined);
    const [event, setEvent] = useState<selectOption | undefined>(undefined);
    const [nTickets, setNTickets] = useState<selectOption | undefined>({value: '1', label: '1'});
    const [allAvailableTicket, setAllAvailableTicket] = useState<number>(1);
    const teamProvider = useTeam();

    const populateIssuerOptions = (propsEvents: Event[]): void => {
        let options: selectOption[] = []
        if (teamProvider.GetEventIssuer() !== undefined) {
            if (propsEvents.length > 0) {
                options = [
                    {
                        value: propsEvents[0].mainIssuerUuid,
                        label: propsEvents[0].mainIssuer
                    }
                ]
            }
        } else {
            propsEvents.forEach(e => {
                options = _.uniqBy([
                    ...options,
                    {value: e.mainIssuerUuid, label: e.mainIssuer},
                    {value: e.secondaryIssuerUuid, label: e.secondaryIssuer},
                ], "value")
            })
        }
        if (options) setIssuersOptions(options); setIssuer(options[0])
        populateEventOption(propsEvents)
    }

    const populateEventOption = (propsEvents = events): void => {
        const options = propsEvents?.filter((event) => {
                return (event.mainIssuerUuid === issuer?.value || event.secondaryIssuerUuid === issuer?.value)
            }).map((event) => {
                return {value: event.uuid, label: event.name}
            }) || []
        setEventsOptions(options)
        setEvent(options[0])
    }

    useEffect(() => {
        let finalEvents = []
        if (teamProvider.GetEventIssuer() !== undefined){
            const issuerUuid = teamProvider.GetEventIssuer()
            for (const event of props.events){
                if (event.mainIssuerUuid === issuerUuid || event.secondaryIssuerUuid === issuerUuid){
                    finalEvents.push(event)
                }
            }
        } else {
          finalEvents = props.events
        }
        setEvents(finalEvents)
        let allTickets = 0;
        finalEvents.forEach(e => {allTickets += e.availableTickets})
        setAllAvailableTicket(allTickets)
        populateIssuerOptions(finalEvents)

    }, [])

    useEffect(() => {
        populateEventOption()
    }, [issuer])

    const selectStylesDesktop: StylesConfig = {
        control: (styles) => (
            {...styles, backgroundColor: variables.background, border: "0", boxShadow: "none", color: variables.mainText, fontSize: 14, cursor: 'pointer',
            }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
        dropdownIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        valueContainer: (styles) => ({ ...styles, padding:0, fontSize: 14, color: variables.mainText}),
        menu: (styles) => ({ ...styles, fontSize: 14, color: `${variables.mainText} !important`}),
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                color: variables.mainText,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: variables.mainText, fontSize: 14}),
    }

    const selectStylesMobile: StylesConfig = {
        control: (styles) => (
            {...styles,
                backgroundColor: variables.background, border: "0", padding: 5,
                paddingLeft: 10, boxShadow: "none", color: variables.mainText, fontSize: 14,
                cursor: 'pointer',
            }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
        dropdownIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        valueContainer: (styles) => ({ ...styles, padding:0, fontSize: 14, color: variables.mainText}),
        menu: (styles) => ({ ...styles, fontSize: 14, color: `${variables.mainText} !important`}),
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                color: isSelected ? variables.mainText : variables.mainText,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: variables.mainText, fontSize: 14}),
    }

    if (!events) return <Loader />

    return (
        <div className='hero'>
            <div className='container'>
                <div className='row'>
                    <div className='col-2 d-none d-md-block'>
                        <div className='btn btn--gradient announcements__tag'>Novità</div>
                    </div>
                    <div className='w-100'></div>
                    <div className='col-10 col-md-7 col-lg-7'>
                        <h1 className='announcements__header'>Condividi la passione per il <span
                            className='font-weight-bold text--primary-color'>Pisa S.C.</span></h1>
                    </div>
                    <div className='w-100'></div>
                    <div className='col-8 col-md-7 col-lg-5 mb-sm-3'>
                        <p className='announcements__paragraph'>Acquista e vendi i biglietti della tua squadra del cuore, con la Membership Pisa Card.</p>
                    </div>
                </div>

                <div className='row d-none d-md-flex'>
                    <div className={teamProvider.GetEventIssuer() === undefined ? 'ticket-search col-12 col-lg-10' : 'ticket-search col-12 col-lg-8 col-xl-7'}>
                        <form action='' className='ticket-search__form'>
                            <div className='row justify-content-between ticket-search__row'>
                                {/* ISSUER */}
                                {
                                    teamProvider.GetEventIssuer() === undefined ?
                                        <div className='col-3 px-3 ps-4 ticket-search-separator'>
                                            <label className='ticket-search__label' htmlFor='team-select'>Squadra</label>
                                            <Select
                                                defaultValue={issuersOptions[0] ?? undefined}
                                                options={issuersOptions}
                                                placeholder={"Squadra"}
                                                styles={selectStylesDesktop}
                                                value={issuer}
                                                onChange={(ev:any) => {
                                                    setIssuer(ev)
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                }
                                {/* EVENT */}
                                <div className={teamProvider.GetEventIssuer() === undefined ? 'col-3 px-3 ticket-search-separator' : 'col-5 px-3 ticket-search-separator'}>
                                    <label className='ticket-search__label' htmlFor='match'>Partita</label>
                                    <Select
                                        defaultValue={eventsOptions[0] ?? undefined}
                                        options={eventsOptions}
                                        placeholder={"Partita"}
                                        styles={selectStylesDesktop}
                                        value={event}
                                        onChange={(ev:any) => {
                                            setEvent(ev)
                                        }}
                                    />
                                </div>

                                {/* N BIGLIETTI */}
                                <div className={teamProvider.GetEventIssuer() === undefined ? 'col-2 px-3' : 'col-3 px-3'}>
                                    <label className='ticket-search__label' htmlFor='ticket-number'>N. Biglietti</label>
                                    <Select
                                        defaultValue={nTicketsOptions[0]}
                                        options={nTicketsOptions}
                                        placeholder={"N° Biglietti"}
                                        styles={selectStylesDesktop}
                                        value={nTickets}
                                        onChange={(ev:any) => {
                                            setNTickets(ev)
                                        }}
                                    />
                                </div>
                                {/* BUTTON CERCA BIGLIETTI */}
                                <div className='col-4 text-center d-flex align-items-center justify-content-end'>
                                    <button className='btn btn--gradient ml-auto me-3 px-sm-3' onClick={()=>{
                                        navigate(`/Partita/${event?.value}?tickets=${nTickets?.value}`)
                                    }}>CERCA BIGLIETTI</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='available_tickets col-12 d-none d-md-block align-items-center'>
                        <i aria-hidden="true" className="available_tickets__icon icon icon--ticket mx-auto"/>
                        <div className='label available_tickets__label'>
                            Ci sono { allAvailableTicket } biglietti in vendita!
                            <a className='available_tickets__link label' onClick={() => {navigate("/Partite")}}> Dai un’occhiata</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ticket-search d-md-none'>
                <form action='' className='ticket-search__form container-fluid'>
                    <div className='row m-0 ticket-search__row no-gutters'>
                        {
                            teamProvider.GetEventIssuer() === undefined ?
                                <div className='col-6'>
                                    <label className='ticket-search__label my-2' htmlFor='team-select'>Squadra</label>
                                    <Select
                                        defaultValue={issuersOptions[0] ?? undefined}
                                        options={issuersOptions}
                                        placeholder={"Squadra"}
                                        styles={selectStylesMobile}
                                        value={issuer}
                                        onChange={(ev:any) => {
                                            setIssuer(ev)
                                        }}
                                    />
                                </div>
                                :
                                null
                        }
                        <div className={teamProvider.GetEventIssuer() === undefined ? 'col-6': 'col-12'}>
                            <div className='ticket-search__match'>
                                <label className='ticket-search__label my-2' htmlFor='match'>Partita</label>
                                <Select
                                    defaultValue={eventsOptions[0] ?? undefined}
                                    options={eventsOptions}
                                    placeholder={"Partita"}
                                    styles={selectStylesMobile}
                                    value={event}
                                    onChange={(ev:any) => {
                                        setEvent(ev)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-12 ticket-search__col-md ticket-search__col-md-tickets py-4'>
                            <label className='ticket-search__label ticket-search__label--n-ticket pt-2 pb-3' htmlFor='ticket-number-mobile'>
                                N. Biglietti
                                <span className='ticket-search__label__number text--heading-color' id='ticket-slider-counter'>{ nTickets?.value }</span>
                            </label>
                            <RangeSelector
                                id={"numSeats"}
                                name={"numSeats"}
                                min={1}
                                max={3}
                                step={1}
                                initialValue={1}
                                disabled={false}
                                onChange={(val: number) => {
                                    setNTickets({
                                        value: val.toString(),
                                        label: val.toString()
                                    })
                                }}
                            />
                        </div>
                        <div className='col-10 offset-1 col-md-4 offset-md-0 d-flex justify-content-center mb-5'>
                            <button className='btn btn--gradient ml-auto mt-2'  onClick={()=>{
                                navigate(`/Partita/${event?.value}?tickets=${nTickets?.value}`)
                            }}
                            >
                                CERCA BIGLIETTI
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
  )
}

export default Hero
